import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown/with-html'

import SEO from '@/components/SEO/Seo'
import Layout from '@/components/Layout'

export const PrivacyPageTemplate = ({ data, content }) => {
  return (
    <>
      <div className='full-height-section'>
        <div className='container py-16'>
          <div className='mb-20'>
            <div className='decorator'></div>
            <h1 className='text-center'>{data.title}</h1>
          </div>
          <ReactMarkdown
            allowDangerousHtml
            children={content}
            className='content-page'></ReactMarkdown>
        </div>
      </div>
    </>
  )
}

const PrivacyPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const content = data.markdownRemark.html

  return (
    <Layout>
      <SEO data={frontmatter.seo} />
      <PrivacyPageTemplate data={frontmatter} content={content} />
    </Layout>
  )
}

PrivacyPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export const pageQuery = graphql`
  query PrivacyPageTemplate {
    markdownRemark(frontmatter: { template: { eq: "privacy" } }) {
      html
      frontmatter {
        title
        ...SEO
      }
    }
  }
`

export default PrivacyPage
